<template>
  <a
    href="#"
    class="h-5 br-1 flex items-center cursor-pointer js-favourites-trigger px-3 hover:bg-paper-4"
    @click.prevent="$emit('toggle')">
    <div class="flex items-center justify-center">
      <div
        class="heart-icon flex items-center mini-heart-container"
        :class="animationClasses">
        <svg-icon
          name="nav-heart"
          height="16px"
          width="16px" />
      </div>
      <p class="typeset-12 ml-2 hidden md:block relative top-px">
        {{ t("favourites.title") }}
      </p>
    </div>
  </a>
</template>

<script>
import SvgIcon from "../shared/SvgIcon.vue";

export default {
  name: "MiniFavouritesToggle",
  components: {
    SvgIcon
  },
  emits: ["toggle"],
  data() {
    return {
      animateAdded: false,
      animateRemoved: false
    };
  },
  computed: {
    animationClasses() {
      return {
        "animate-added": this.animateAdded,
        "animate-removed": this.animateRemoved
      };
    }
  },
  mounted() {
    window.addEventListener(
      "addedFavourite",
      this.performAddAnimation.bind(this)
    );
    window.addEventListener(
      "removedFavourite",
      this.performRemoveAnimation.bind(this)
    );
  },
  methods: {
    performAddAnimation() {
      this.animateRemoved = false;
      this.animateAdded = true;
      setTimeout(() => {
        this.animateAdded = false;
      }, 1500);
    },
    performRemoveAnimation() {
      this.animateAdded = false;
      this.animateRemoved = true;
      setTimeout(() => {
        this.animateRemoved = false;
      }, 1500);
    }
  }
};
</script>

<style lang="scss" scoped>
.mini-heart-container {
  &.animate-added {
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    animation-name: heartAddAnimation;
    animation-timing-function: linear;
  }

  &.animate-removed {
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: heartRemoveAnimation;
    animation-timing-function: linear;
  }
}

@keyframes heartAddAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
    color: var(--red);
  }
}
@keyframes heartRemoveAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
}
</style>
