import { createApp } from "vue";
import { ready } from "../utils/listeners";

import I18n from "../plugins/i18n";
import QuickAddGreetingCard from "../components/listings/QuickAddGreetingCard.vue";

window.setupQuickAddGreetingCard = () => {
  const node = document.getElementById("quick-add-greeting-cards");
  if (!node) return;

  const props = JSON.parse(node.dataset.props);
  const translations = JSON.parse(node.dataset.translations);

  const app = createApp(QuickAddGreetingCard, props);

  app.use(I18n, translations);

  app.mount(node);
};

ready(() => {
  window.setupQuickAddGreetingCard();
});
