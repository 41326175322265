document.addEventListener("DOMContentLoaded", () => {
  const gnav = document.querySelector(".gnav");
  const body = document.querySelector("body");

  const toggleNavClasses = isIntersecting => {
    if (body.classList.contains("fold-homepage")) {
      gnav.classList.toggle("bg-paper-2", !isIntersecting);
      gnav.classList.toggle("text-white", isIntersecting);
    } else if (gnav) {
      gnav.classList.toggle("swap", !isIntersecting);
    }
    gnav.classList.toggle("shadow-1", !isIntersecting);
  };

  // Dynamically change theme-color on scroll
  const theme = document.querySelector('meta[name="theme-color"]');
  let currentThemeColor = "rgb(250, 247, 240)";

  // Here's the code to transition/animate the color change — it's a lot.
  // TL;DR, we have to calculate each "frame" and update the <meta> tag as you go.
  // This was remixed from a Stack Overflow answer by the user "ffdigital"
  // https://stackoverflow.com/a/38381724/4746353

  function colorTransition(currentColorArray, targetColorArray, increment) {
    // Checking R (from RGB)
    if (currentColorArray[0] > targetColorArray[0]) {
      currentColorArray[0] -= increment[0];

      if (currentColorArray[0] <= targetColorArray[0]) {
        increment[0] = 0;
      }
    } else {
      currentColorArray[0] += increment[0];

      if (currentColorArray[0] >= targetColorArray[0]) {
        increment[0] = 0;
      }
    }

    // Checking G (from RGB)
    if (currentColorArray[1] > targetColorArray[1]) {
      currentColorArray[1] -= increment[1];

      if (currentColorArray[1] <= targetColorArray[1]) {
        increment[1] = 0;
      }
    } else {
      currentColorArray[1] += increment[1];

      if (currentColorArray[1] >= targetColorArray[1]) {
        increment[1] = 0;
      }
    }

    // Checking B (from RGB)
    if (currentColorArray[2] > targetColorArray[2]) {
      currentColorArray[2] -= increment[2];

      if (currentColorArray[2] <= targetColorArray[2]) {
        increment[2] = 0;
      }
    } else {
      currentColorArray[2] += increment[2];

      if (currentColorArray[2] >= targetColorArray[2]) {
        increment[2] = 0;
      }
    }

    // Apply the new modified color
    theme.setAttribute("content", `rgb(${currentColorArray})`);
  }

  function animateColor(
    duration,
    currentColorArray,
    targetColorArray,
    increment,
    stop,
    fpsInterval,
    now,
    then,
    elapsed,
    startTime
  ) {
    const step = function () {
      if (stop) {
        return;
      }
      // Request another frame
      requestAnimationFrame(function () {
        // arguments can passed on the callback by an anonymous function
        animateColor(
          duration,
          currentColorArray,
          targetColorArray,
          increment,
          stop,
          fpsInterval,
          now,
          then,
          elapsed,
          startTime
        );
        colorTransition(currentColorArray, targetColorArray, increment);
      });

      let sinceStart = 0;

      // Calculate the elapsed time since last loop
      now = Date.now();
      elapsed = now - then;

      // If enough time has elapsed, draw the next frame
      if (elapsed > fpsInterval) {
        // Get ready for next frame by setting then=now, but...
        // Also, adjust for fpsInterval not being multiple of 16.67
        then = now - (elapsed % fpsInterval);
        sinceStart = now - startTime;
      }

      if ((sinceStart / 1000) * 100 >= duration * 100) {
        stop = true;
        // Update the currentThemeColor for the next transition
        currentThemeColor = `rgb(${currentColorArray})`;
      }
    };

    step();
  }

  function getElementBG(elmBGColor) {
    let bg = elmBGColor; // i.e: RGB(255, 0, 0)
    [, bg] = bg.match(/\((.*)\)/);
    bg = bg.split(",");

    for (let i = 0; i < bg.length; i += 1) {
      bg[i] = parseInt(bg[i], 10);
    }

    if (bg.length > 3) {
      bg.pop();
    }

    return bg; // return array
  }

  function calculateDistance(colorArray1, colorArray2) {
    const distance = [];

    for (let i = 0; i < colorArray1.length; i += 1) {
      distance.push(Math.abs(colorArray1[i] - colorArray2[i]));
    }

    return distance;
  }

  function calculateIncrement(distanceArray, fps, duration) {
    const increment = [];
    for (let i = 0; i < distanceArray.length; i += 1) {
      increment.push(Math.abs(Math.floor(distanceArray[i] / (fps * duration))));

      if (increment[i] === 0) {
        increment[i] += 1;
      }
    }

    return increment;
  }

  function startColorFade(fps, duration, currentColor, targetColor) {
    const stop = false;
    const fpsInterval = 1000 / fps;
    let now;
    const then = Date.now();
    let elapsed;
    const startTime = then;
    const currentColorArray = getElementBG(currentColor);
    const targetColorArray = getElementBG(targetColor);
    const distance = calculateDistance(currentColorArray, targetColorArray);
    const increment = calculateIncrement(distance, fps, duration);
    animateColor(
      duration,
      currentColorArray,
      targetColorArray,
      increment,
      stop,
      fpsInterval,
      now,
      then,
      elapsed,
      startTime
    );
  }

  const options = {
    threshold: 1.0
  };

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      let color;

      if (entry.isIntersecting) {
        color = "rgb(250, 247, 240)";
      } else {
        color = "rgb(255, 254, 250)";
      }

      startColorFade(60, 0.3, currentThemeColor, color);
      toggleNavClasses(entry.isIntersecting);
    });
  }, options);

  observer.observe(gnav);
});
